<template>
  <div>
    <v-dialog v-model="dialog" width="500" @click:outside="closeDialog">
      <v-card>
        <v-icon v-if="currentRouteName == 'inbox'" class="float-right ma-4" size="20" @click="closeDialog">
          {{ icons.mdiCloseThick }}
        </v-icon>

        <v-card-title color="error">
          {{ title }}
        </v-card-title>

        <v-card-text>
          {{ text }}
        </v-card-text>

        <v-card-actions>
          <v-btn v-show="text !== 'Phone Number is Not Valid'" color="primary" class="" @click="openConfig">
            {{ `${$t('add')}` }}
          </v-btn>
          <dialogLabel ref="dialogLabel"></dialogLabel>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="closeDialog"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mdiCloseThick } from '@mdi/js'
import dialogLabel from '../../views/component/DialogLabel.vue'

export default {
  setup() {
    return {
      icons: { mdiCloseThick },
    }
  },
  name: 'AlertDialog',
  components: {
    dialogLabel,
  },
  props: ['dialog', 'title', 'text', 'selected-room-id'],
  data() {
    return {
      labelData: [],
      labelValue: [],
    }
  },
  emits: ['close'],
  computed: {
    currentRouteName() {
      return this.$route.name
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    room() {
      return this.$store.getters['global/getCurrentRoom']
    },
  },
  async mounted() {
    await this.getLabelValue()
    // //console.log(this.currentRouteName, '<<<< ROUTE')
  },
  methods: {
    closeDialog() {
      this.$emit('close')
    },
    openConfig() {
      // if (this.labelData?.find(el => el.channelId === this.room.instance_id) === undefined) {
      //   this.$store.commit('inbox/setEmptyLabel', true)
      // }
      //console.log(this.labelData, 'ini blabel')
      this.$refs.dialogLabel.open(this.labelData, this.selectedRoomId).then(async confirm => {
        if (confirm.status) {
          await this.getLabelValue()
          await this.closeDialog()
          this.labelData = []
        }
      })
    },
    async getLabelValue() {
      // const labelsPg = await this.$store.dispatch('global/getSettingsByKey', {
      //   sub_id: this.user.sub_id,
      //   token: this.user.token,
      //   key: 'label',
      // })
      // if (labelsPg.status && labelsPg.data) {
      //   this.labelData = JSON.parse(JSON.stringify(labelsPg.data.value))
      // }
      // const labelsValuePg = await this.$store.dispatch('global/getSettingsByKey', {
      //   sub_id: this.user.sub_id,
      //   token: this.user.token,
      //   key: 'label_value',
      // })
      // if (labelsValuePg.status) {
      //   this.labelValue = JSON.parse(JSON.stringify(labelsValuePg.data.value))
      // }
    },
  },
}
</script>
