import PouchDB from 'pouchdb-browser'
import contactMixin from './contactMixin'
import pouchDbMixin from './pouchDbMixin'

PouchDB.adapter('worker', require('worker-pouch'))

export default {
  data() {
    return {
      remoteEFormSurvey: null,
    }
  },
  mixins: [pouchDbMixin, contactMixin],
  methods: {
    async getEForm() {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/e-form/get-eform`
      const { token } = this.$store.getters['auth/getUser']
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }

      let eForms = []
      await this.$axios
        .get(endpoint, config)
        .then(response => {
          if (response.data.status) {
            response.data.data.forEach(data => {
              data.formatted_created_at = this.$moment.unix(data.created_at).format('DD/MM/YYYY HH:mm:ss')
            })
            // //console.log(response.data.data)
            this.eForm = response.data.data
            eForms = response.data.data
          }
        })
        .catch(error => {
          console.log(error)
        })

      return eForms
    },
    async createEForm(data) {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/e-form/create-eform`
      const { token } = this.$store.getters['auth/getUser']
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }

      await this.$axios
        .post(endpoint, data, config)
        .then(response => {
          if (response.data.status) {
            this.createSuccess = true
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    async editEForm(data) {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/e-form/update-eform`

      const { token } = this.$store.getters['auth/getUser']
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }

      await this.$axios
        .post(endpoint, data, config)
        .then(response => {
          if (response.data.status) {
            this.createSuccess = true
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    async getEFormById(data) {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/e-form/get-eform-by-id`
      let result = null
      await this.$axios
        .post(endpoint, data)
        .then(response => {
          if (response.data.status) {
            if (response.data.data) {
              this.eForm = response.data
            }
          }
          result = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
      return result
    },
    async onComplete(data, room, id, contactList) {
      const subId = id.split('-')

      // let nameAfter = decodeURI(name)
      const tokenId = this.$nanoid()
      const obj = {}
      this.selectedSurvey.survey.pages.forEach(el => {
        el.elements.forEach(item => {
          // //console.log('🚀 ~ file: eFormMixin.js ~ line 106 ~ onComplete ~ item', item)
          if (data.hasOwnProperty(item.name)) {
            if (item.hasOwnProperty('title')) {
              obj[item.title] = data[item.name]
            } else {
              obj[item.name] = data[item.name]
            }
          }
        })
      })

      // //console.log('🚀 ~ file: eFormMixin.js ~ line 111 ~ onComplete ~ obj', obj)

      const payload = {
        _id: tokenId,
        eform_survey_id: 'none',
        contactList: this.selectedSurvey.contactList,
        answerData: obj,
        timestamp: Date.now(),
        sub_id: subId[0],
      }

      try {
        const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/e-form/add-or-update-answer`
        const res = await this.$axios.post(endpoint, payload)
        if (res.data.status) {
          const selectedList = contactList

          for (const contact of selectedList.contacts) {
            if (contact.phone_number === room.phone_number) {
              if (!contact.answerData) {
                contact.answerData = []
                contact.answerData = [obj, ...contact.answerData]
                contact.updated_at = Date.now()
              } else {
                contact.answerData = [obj, ...contact.answerData]
                contact.updated_at = Date.now()
              }
            }
          }

          await this.$store.dispatch('global/insertOrUpdateContactList', {
            sub_id: this.user.sub_id,
            token: this.user.token,
            ...selectedList,
          })

          this.openDialogSurvey = false
        }
      } catch (err) {
        console.log(err)
      }
    },
    async getEFormByContactList(data) {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/e-form/get-eform-by-contact-list`
      const { token } = this.$store.getters['auth/getUser']
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }

      try {
        const res = await this.$axios.post(endpoint, data, config)

        return res.data
      } catch (err) {
        return err
      }
    },
    async updateEFormFields(data) {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/e-form/update-eform-fields`
      const { token } = this.$store.getters['auth/getUser']
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }

      try {
        const res = await this.$axios.post(endpoint, data, config)

        return res.data
      } catch (err) {
        return err
      }
    },
    async updateSurveyStatus(data) {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/e-form/update-survey-status`
      const { token } = this.$store.getters['auth/getUser']
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }

      try {
        const res = await this.$axios.post(endpoint, data, config)

        return res.data
      } catch (err) {
        return err
      }
    },
    async getEFormForSurvey() {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/e-form/get-eform-for-survey`
      const { token } = this.$store.getters['auth/getUser']
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }

      try {
        const res = await this.$axios.get(endpoint, config)

        return res.data.data
      } catch (err) {
        return err
      }
    },
    async showHideFromQuickMenu(data) {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/e-form/show-hide-from-quick-menu`
      const { token } = this.$store.getters['auth/getUser']
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }

      try {
        const res = await this.$axios.post(endpoint, data, config)

        return res.data
      } catch (err) {
        return err
      }
    },

    // eform survey
    async connectEFormSurvey() {
      const host = `${process.env.VUE_APP_COUCH_DB_DIRECT_URL}/eform_survey`
      const remote = new PouchDB(host, {
        fetch(url, opts) {
          opts.headers.set('Content-Type', 'application/json')

          return PouchDB.fetch(url, opts)
        },
      })

      this.remoteEFormSurvey = remote
    },
    async onCompleteSurvey(data) {
      const payload = {
        _id: this.$nanoid(),
        eform_survey_id: this.eFormSurveyId,
        ...data,
      }

      await this.remoteEFormSurvey
        .put(payload)
        .then(async response => {})
        .catch(error => {
          console.log(error)

          this.$dialog.message.error('Failed to complete survey')
        })
    },

    // eform analytics
    async getEFormAnalytics(data) {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/e-form/get-eform-analytics`
      const { token } = this.$store.getters['auth/getUser']
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }

      await this.$axios
        .post(endpoint, data, config)
        .then(response => {
          if (response.data.status) {
            this.data = response.data.data
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    async deleteEformDb(data) {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/e-form/delete-eform`
      const { token } = this.$store.getters['auth/getUser']

      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
      const obj = {
        // sub_id: this.user.sub_id,
        eform_id: data,
      }

      await this.$axios
        .post(endpoint, obj, config)
        .then(response => {
          //console.log(response)
          if (response.data.status) {
            this.getEForm()

            // this.data = response.data.data
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    async deleteEformDbandContactList(data) {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/e-form/delete-eform-and-contact-List`
      const { token } = this.$store.getters['auth/getUser']

      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
      const obj = {
        // sub_id: this.user.sub_id,
        eform_id: data.eform_id,
        contactList: data.contactList,
      }

      await this.$axios
        .post(endpoint, obj, config)
        .then(response => {
          //console.log(response)
          if (response.data.status) {
            this.getEForm()

            // this.data = response.data.data
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    async deleteEformAnswerDb(data) {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/e-form/delete-eform-answer`

      // const { token } = this.$store.getters['auth/getUser']
      // const config = {
      //   headers: {
      //     authorization: `Bearer ${token}`,
      //   },
      // }
      const obj = {
        // sub_id: this.user.sub_id,
        eform_id: data,
      }

      await this.$axios
        .post(endpoint, obj)
        .then(response => {
          //console.log(response)
          // if (response.data.status) {
          //   this.getEForm()
          //   // this.data = response.data.data
          // }
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
