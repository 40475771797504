<template>
  <div>
    <!-- <pre>{{phoneNumber}}</pre> -->
    <alert-dialog :dialog="alertDialog" :title="alertTitle" :text="alertText" @close="alertDialog = false" />
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <v-select
              v-model="phoneNumber"
              :items="instances"
              item-text="label"
              item-value="value"
              outlined
              :label="`${$t('channels.channel')} *`"
              hide-details
              required
              class="mb-3"
              attach
            ></v-select>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <v-text-field v-model="eFormTitle" :label="`${$t('eform.title')} *`" outlined></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <v-text-field
              v-model="eFormFacebookPixel"
              required
              label="Pixel ID"
              :prepend-inner-icon="icons.mdiCounter"
              outlined
              hide-details
              class="mb-3"
              type="number"
              placeholder="1236776XXX"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <v-text-field v-model="eFormDescription" :label="`${$t('eform.description')} *`" outlined></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <v-text-field
              v-model="eFormGoogleTagManager"
              required
              label="Tag Manager ID"
              :prepend-inner-icon="icons.mdiPoundBoxOutline"
              outlined
              placeholder="GTM-123XXX"
              class="mb-3"
              :hint="$t('LinkGenerator.tutorialSlugFour')"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col v-if="edit" cols="12" lg="6" md="6" sm="12" xs="12">
            <v-select
              v-model="eFormSelectedFields"
              required
              multiple
              :label="$t('eform.selectedFields')"
              :prepend-inner-icon="icons.mdiPoundBoxOutline"
              outlined
              :items="availableFields"
              class="mb-3"
              :hint="$t('eform.selectedFields')"
              persistent-hint
            ></v-select>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <v-checkbox
              v-model="eFormAddToSurvey"
              class="mb-3"
              color="primary"
              :label="`${$t('eform.addToSurvey')}`"
              v-if="!isFromCustomerGroup"
            >
            </v-checkbox>
            <v-checkbox
              v-model="eFormAddToQuickReplay"
              class="mb-3"
              color="primary"
              :label="`${$t('eform.addToQuickReplay')}`"
              v-else
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12">
            <survey-creator-new :is-edit="edit" @save-survey="saveSurvey" />
          </v-col>
        </v-row>
      </v-card-text>
      <dialogAddListContact ref="dialogAddListContact"></dialogAddListContact>
    </v-card>
  </div>
</template>
<script>
import {
  mdiFormSelect,
  mdiInformationOutline,
  mdiPlus,
  mdiRadioTower,
  mdiCounter,
  mdiImageFilterCenterFocusWeak,
  mdiPoundBoxOutline,
  mdiPlusCircle,
} from '@mdi/js'
import PouchDB from 'pouchdb-browser'
import eFormMixin from '@/mixins/eFormMixin'
import SurveyCreator from '@/components/surveyjs/SurveyCreator.vue'
import SurveyCreatorNew from '@/components/surveyjs/SurveyCreatorNew.vue'
import AlertDialog from '@/components/dialog/AlertDialog.vue'
import pouchDbMixin from '../../mixins/pouchDbMixin'
import contactMixin from '../../mixins/contactMixin'
import dialogAddListContact from '../component/DialogAddListContact.vue'

PouchDB.adapter('worker', require('worker-pouch'))

export default {
  name: 'EFormCreate',

  components: {
    SurveyCreator,
    SurveyCreatorNew,
    AlertDialog,
    dialogAddListContact,
  },

  mixins: [eFormMixin, pouchDbMixin, contactMixin],
  props: ['edit'],

  setup() {
    return {
      icons: {
        mdiFormSelect,
        mdiInformationOutline,
        mdiPlus,
        mdiRadioTower,
        mdiCounter,
        mdiImageFilterCenterFocusWeak,
        mdiPoundBoxOutline,
        mdiPlusCircle,
      },
    }
  },

  data() {
    return {
      phoneNumber: '',
      dataEdit: false,
      createSuccess: false,
      contactListSelected: {},
      eForm: [
        {
          id: 1,
          title: 'Form 1',
          description: 'Lorem ipsum',
          created_at: '06/12/2021',
        },
      ],
      availableFields: [],
      eFormTitle: this.$t('eform.titlePlaceholder'),
      eFormDescription: this.$t('eform.descriptionPlaceholder'),
      eFormFacebookPixel: null,
      eFormGoogleTagManager: null,
      eFormAddToSurvey: false,
      eFormAddToQuickReplay: false,
      eFormSelectedFields: [],

      // alert
      alertDialog: false,
      alertTitle: null,
      alertText: null,
      contactListTable: [],
      listContactValue: [],
      labelData: [],
      contactListSetting: null,
      
      currentContactList: {},
    }
  },

  computed: {
    instances() {
      const listInstance = []
      this.$store.getters['auth/getListInstance'].value.forEach(instance => {
        listInstance.push({
          label: `${instance.label} - (${instance.phone_number})`,
          value: instance.phone_number,
        })
      })

      return listInstance
    },
    allSettings() {
      return this.$store.getters['global/getSettings']
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    dataListContact2() {
      return this.listContactValue
    },
    isFromCustomerGroup() {
      return this.$route.params.fromCustomerGroup
    },
  },
  async mounted() {
    await this.loadSetting(true)
    await this.loadTable();
    const idlist = this.$route.params.idlist;
    if (idlist) {
      this.getContactListById(idlist);
    }

    if (this.edit) {
      // //console.log(this.$store.getters['link/getSaveDataEform'])
      const dataForm = this.$store.getters['link/getSaveDataEform']
      this.availableFields = []
      for (const form of dataForm.survey.pages) {
        // //console.log(form.elements)
        for (const element of form.elements) this.availableFields.push(element.title)
      }

      this.phoneNumber = dataForm.channel
      this.dataEdit = dataForm
      this.eFormTitle = dataForm.title
      this.eFormDescription = dataForm.description
      this.eFormFacebookPixel = dataForm.facebook_pixel_id
      this.eFormGoogleTagManager = dataForm.google_tag_manager_id
      this.eFormAddToSurvey = dataForm.addToSurvey
      this.eFormAddToQuickReplay = dataForm.addToQuickReplayMenu;
      this.eFormSelectedFields = dataForm.selectedFields
    }

    // //console.log(this.dataEdit)

    // const tokenLife = await this.$store.dispatch('auth/checkTokenLife', { token: this.user.token })
    // if (tokenLife.statusCode === 401) {
    //   this.redirectDialog = true
    //   setTimeout(() => {
    //     this.$router.replace({ name: 'login' })
    //   }, 1500)
    // }
    // await this.$store.dispatch('link/fetchListMessage', {
    //   sub_id: this.user.sub_id,
    //   token: this.user.token,
    // })

    // for (const data of this.instances) {
    //   const res = await this.$store.dispatch('auth/checkStatusConnection', `${data.sub_id}-${data.instance_id}`)

    //   if (res) {
    //     this.optionsInstances.push({
    //       label: `${data.label} (${data.phone_number})`,
    //       value: data,
    //     })
    //   }
    // }

    this.$eventBus.$on(process.env.VUE_APP_EVENT_UPDATE_SETTINGS, async payload => {
      if (payload.id === 'contact_list') {
        await this.$store.dispatch('global/setSettingsById', payload)
        this.replicateFromPouchDbGlobal(process.env.VUE_APP_DB_SETTING_NAME, this.user, async data => {
          await this.loadSetting(true)
          await this.loadTable()
        })

        // await this.loadSetting()
      }
    })
  },

  methods: {
    openNewList() {
      this.$refs.dialogAddListContact.open('').then(async confirm => {
        if (confirm.status) {
          this.loading = true
          this.listContactValue.push({
            id: this.$nanoid(),
            list_name: confirm.name,
            deleteable: true,
            created_at: new Date().getTime(),
            last_modified: new Date().getTime(),
          })

          const dataContactlist = await this.loadSettingsById('contact_list', true)
          if (dataContactlist) dataContactlist.value = this.listContactValue

          this.updateSettings(dataContactlist, async x => {})
            .then(async r => {
              const { id } = await this.listContactValue[this.listContactValue.length - 1]
              await this.addContactListColumn(id)
              await this.loadSetting()
              await this.loadTable()

              // //console.log(id, '==============================================')
              this.contactListSelected = this.listContactValue.find(el => {
                return el.id === id
              })

              this.loading = false

              // this.importContact({
              //   status: true,
              //   data: id,
              // }).then(async () => {
              //   this.replicateFromPouchDbGlobal(process.env.VUE_APP_DB_CONTACTS_NAME, this.user, async info => {
              //     await this.loadSetting()
              //     await this.loadTable()
              //     this.loading = false
              //   })
              //   // await this.loadSetting()
              // })
            })
            .catch(eror => {
              this.loading = false
            })
        }
      })
    },
    async loadTable() {
      const obj = (await this.loadContactsAll()).contacts.filter(el => el._id !== el.phone_number)
      const listContactObj = this.listContactValue.map(el => {
        //console.log(el.id)
        el.active_contact = obj.filter(elm => elm._id.substring(0, 8) == el.id).length

        return el
      })
      this.listContactValue = [...listContactObj]
      this.totalContact = obj.totalContact
    },
    async loadSetting(isExist = false) {
      if (!isExist) {
        await this.loadSettings()
      }
      const res = await this.loadSettingContactsList(this.allSettings)
      this.labelData = res.labelData
      this.contactListSetting = res.contactListSetting
      this.listContactValue = res.listContactValue

      this.deleteUnusedContact(this.contactListTable, this.listContactValue)
    },
    async saveSurvey(survey) {
      if (JSON.stringify(survey) === JSON.stringify({ pages: [{ name: 'page1' }] })) {
        this.alertDialog = true
        this.alertTitle = 'Oops...'
        this.alertText = 'survey is empty'
      } else if (this.eFormTitle && !this.edit && this.phoneNumber) {
        const data = {
          title: this.eFormTitle,
          description: this.eFormDescription,
          facebook_pixel_id: this.eFormFacebookPixel,
          google_tag_manager_id: this.eFormGoogleTagManager,
          channel: this.phoneNumber,
          addToSurvey: this.eFormAddToSurvey,
          addToQuickReplayMenu: this.eFormAddToQuickReplay,
          selectedFields: [],
          contactList: this.currentContactList.contacts,
          survey,
          created_at: Date.now(),
        }

        if (this.isFromCustomerGroup) {
          const newColumns = this.currentContactList.columns
          newColumns.push({
            id: newColumns.length + 1,
            name: 'answerData',
            align: 'left',
            label: 'Answer Data',
            field: 'answerData',
            value: 'answerData',
            text: 'Answer Data',
            sortable: true,
            type: 'text',
            show: true,
            showable: true,
            formatDate: '',
            deleteable: false,
            isRequired: true,
            defaultValue: '',
            editable: true,
            order_number: newColumns.length + 1,
          })
          data.fromSystem = false
          data.idlist = this.$route.params.idlist
          data.columns = newColumns
        } else {
          data.fromSystem = true
        }

        await this.createEForm(data)
        if (this.createSuccess) {
          await this.loadSetting()
          //  this.$store.getters['global/getContacts'].filter(x => x._id.startsWith())
          await this.loadTable()
          this.$router.push({ name: 'e-form' }, () => {})
        }
      } else if (this.eFormTitle && this.edit && this.phoneNumber) {
        const dataForm = this.$store.getters['link/getSaveDataEform']
        const data = {
          title: this.eFormTitle,
          description: this.eFormDescription,
          facebook_pixel_id: this.eFormFacebookPixel,
          google_tag_manager_id: this.eFormGoogleTagManager,

          // sub_id: this.user.sub_id,
          eform_id: dataForm._id,
          contactList: dataForm.contactList,
          addToSurvey: this.eFormAddToSurvey,
          addToQuickReplayMenu: this.eFormAddToQuickReplay,
          selectedFields: this.eFormSelectedFields,
          channel: this.phoneNumber,
          survey,
        }

        await this.editEForm(data)
        if (this.createSuccess) {
          await this.loadSetting()

          //  this.$store.getters['global/getContacts'].filter(x => x._id.startsWith())

          await this.loadTable()
          this.$router.push({ name: 'e-form' }, () => {})
        }
      } else {
        this.alertDialog = true
        this.alertTitle = 'Oops...'
        this.alertText = this.$t('eform.titleEmpty')
      }
    },
    async getContactListById(idlist) {
       const { data } = await this.$store.dispatch('global/getContactLists', {
          sub_id: this.user.sub_id,
          token: this.user.token,
          list_id: idlist
        })
      this.currentContactList = data;
    }
  },
}
</script>
