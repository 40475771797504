<template>
  <!-- dialog config -->
  <v-dialog v-model="dialogLabel" width="841px">
    <v-card>
      <v-card-title>
        <h3 class="font-weight-bold pl-4" :style="'color: black !important; font-size: 24px'">
          {{ $t('contacts.labels.configLabelDialog.configLabel') }}
        </h3>
        <v-spacer></v-spacer>
      </v-card-title>

      <div class="px-2 mx-3 mt-3">
        <v-card elevation="0" outlined height="410" width="739.23" class="mb-4 mx-auto py-auto px-auto">
          <v-card-text class="height-dialog py-0 px-auto">
            <!-- <div class="d-flex" v-if="listLabel && listLabel.length > 0" v-for="(label, index) in listLabel" :key="label.id">
              <v-menu v-model="menu[index]" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                      <v-icon size="26" :style="{ color: label.color }" v-on="on">
                        {{ icons.mdiLabelVariant }}
                      </v-icon>
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="listLabel[index].color" flat />
                      </v-card-text>
                    </v-card>
                  </v-menu>
            </div> -->

            <div v-for="(label, index) in listLabel" v-if="$vuetify.breakpoint.xs" :key="label.id" class="mt-8 mx-8">
              <div class="d-flex">
                <v-menu v-model="menu[index]" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <v-icon class="mt-2" size="26" :style="{ color: label.color }" v-on="on">
                      {{ icons.mdiLabelVariant }}
                    </v-icon>
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker v-model="listLabel[index].color" flat />
                    </v-card-text>
                  </v-card>
                </v-menu>

                <v-text-field
                  v-model="listLabel[index].name"
                  placeholder="Input Label Name "
                  outlined
                  :rules="[validators.required]"
                  dense
                  class="ms-2 my-auto"
                  :style="'margin-bottom: 0px !important;'"
                ></v-text-field>
              </div>

              <v-combobox
                v-model="label.variants.variant"
                :items="listLabel[index].variants.variant"
                small-chips
                clearable
                :label="$t('contacts.labels.configLabelDialog.variant')"
                multiple
                outlined
                dense
                deletable-chips
              >
                <template v-slot:selection="{ variants, item, index, select, selectedVariant }">
                  <v-chip
                    v-bind="variants"
                    clearable
                    :input-value="selectedVariant"
                    close
                    @click="select"
                    @click:close="remove(item)"
                  >
                    <strong>{{ item }}</strong>
                  </v-chip>
                </template>
              </v-combobox>

              <v-combobox
                v-model="label.channels.channel"
                :items="allChannel"
                item-value="phone_number"
                :item-text="item => item.label || item.label_server"
                chips
                clearable
                :label="$t('contacts.labels.configLabelDialog.channel')"
                multiple
                outlined
                dense
                small-chips
                deletable-chips
              >
              </v-combobox>

              <div class="d-flex justify-end">
                <v-btn class="my-auto" icon @click="hideTags(label, label.id, listLabel, index)">
                  <v-icon color="#D11A2A">
                    {{ label.hide ? icons.mdiEyeOffOutline : icons.mdiEyeOutline }}
                  </v-icon>
                </v-btn>

                <v-btn class="my-auto" icon @click.stop="deleteLabel(index, label.id, listLabel)">
                  <v-icon color="#D11A2A">
                    {{ icons.mdiTrashCanOutline }}
                  </v-icon>
                </v-btn>
              </div>

              <v-divider class="my-4" />
            </div>

            <v-list v-if="listLabel && listLabel.length > 0 && !$vuetify.breakpoint.xs" dense>
              <v-list-item v-for="(label, index) in listLabel" :key="label.id">
                <v-list-item-avatar class="mt-4 mx-auto d-flex align-self-start">
                  <v-menu v-model="menu[index]" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                      <v-icon size="26" :style="{ color: label.color }" v-on="on">
                        {{ icons.mdiLabelVariant }}
                      </v-icon>
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="listLabel[index].color" flat />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-row>
                    <v-col cols="3" class="mt-4 mx-auto my-auto" :style="'padding: 2px !important'">
                      <v-text-field
                        v-model="listLabel[index].name"
                        placeholder="Input Label Name "
                        outlined
                        :rules="[validators.required]"
                        dense
                        :style="'margin-bottom: 0px !important;'"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="mt-4 mx-auto" :style="'padding: 2px !important'">
                      <v-combobox
                        v-model="label.variants.variant"
                        :items="listLabel[index].variants.variant"
                        small-chips
                        clearable
                        :label="$t('contacts.labels.configLabelDialog.variant')"
                        multiple
                        outlined
                        dense
                        small-chips
                        deletable-chips
                      >
                        <template v-slot:selection="{ variants, item, index, select, selectedVariant }">
                          <v-chip
                            v-bind="variants"
                            clearable
                            :input-value="selectedVariant"
                            close
                            @click="select"
                            @click:close="remove(item)"
                          >
                            <strong>{{ item }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-col>
                    <v-col cols="4" dense class="pa-2 ma-2 mt-3">
                      <!-- item-text="label" -->
                      <v-combobox
                        v-model="label.channels.channel"
                        :items="allChannel"
                        item-value="phone_number"
                        :item-text="item => item.label || item.label_server"
                        chips
                        clearable
                        :label="$t('contacts.labels.configLabelDialog.channel')"
                        multiple
                        outlined
                        dense
                        small-chips
                        deletable-chips
                      >
                      </v-combobox>
                      <!-- @change="instancesUpdated(item)" -->
                    </v-col>
                  </v-row>
                </v-list-item-content>

                <v-list-item-action :style="'margin-left: 0px !important;'" class="d-flex mt-4 align-self-start">
                  <v-row>
                    <v-col>
                      <v-btn icon @click="hideTags(label, label.id, listLabel, index)">
                        <v-icon color="#D11A2A">
                          {{ label.hide ? icons.mdiEyeOffOutline : icons.mdiEyeOutline }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col>
                      <v-btn icon @click.stop="deleteLabel(index, label.id, listLabel)">
                        <v-icon color="#D11A2A">
                          {{ icons.mdiTrashCanOutline }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </div>

      <v-card-actions :class="$vuetify.breakpoint.xs ? 'justify-start ms-n3' : 'justify-end'">
        <v-spacer></v-spacer>
        <v-btn plain @click="cancel">
          <span class="error--text">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary" class="" @click="addNewLabel">
          {{ `${$t('add')}` }}
        </v-btn>
        <v-btn color="primary" @click="agree">
          {{ $t('save') }}
        </v-btn>
        <v-progress-circular v-if="isLoadingLabel" indeterminate color="primary" class="mx-4"></v-progress-circular>
      </v-card-actions>
      <v-snackbar v-model="errorTitleValidationSnackbar" color="error" :top="true" style="z-index: 5 !important">
        Labels Name cannot be empty
        <template #action="{ attrs }">
          <v-btn text v-bind="attrs" @click="errorTitleValidationSnackbar = false">
            {{ $t('Close') }}
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="errorDeleteFormValidationSnackbar" color="error" :top="true" style="z-index: 5 !important">
        This Tag cannot be deleted because it has been registered to contacts
        <template #action="{ attrs }">
          <v-btn text v-bind="attrs" @click="errorDeleteFormValidationSnackbar = false">
            {{ $t('Close') }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
    <dialogDeleteLabel ref="dialogDeleteLabel" @fetch-all-tags="fetchAllTags"></dialogDeleteLabel>
  </v-dialog>
</template>

<script>
import { default as clientPocketBase } from '@/helpers/pocketbase'
import { emailValidator, required } from '@core/utils/validation'
import {
  mdiDeleteOutline,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiLabel,
  mdiLabelVariant,
  mdiTrashCanOutline,
} from '@mdi/js'
import { uuid } from 'vue-uuid'
import pouchDbMixin from '../../mixins/pouchDbMixin'
import dialogDeleteLabel from './DialogDeleteLabel.vue'

export default {
  setup() {
    return {
      validators: {
        required,
        emailValidator,
      },
    }
  },
  components: { dialogDeleteLabel },
  mixins: [pouchDbMixin],
  props: { tags: { type: Array, default: () => [] } },
  data: () => ({
    menu: [],
    dialogLabel: false,
    resolve: null,
    reject: null,
    headersLabel: [
      { value: 'color', text: 'Color' },
      { value: 'label', text: 'Label' },
      { value: 'action', text: 'Action' },
    ],
    errorTitleValidationSnackbar: false,
    errorDeleteFormValidationSnackbar: false,
    columnsSetting: null,
    dbSettings: null,
    dbRemoteSettings: null,
    tableColumns: null,
    listLabel: [],
    icons: {
      mdiLabel,
      mdiLabelVariant,
      mdiDeleteOutline,
      mdiTrashCanOutline,
      mdiEyeOffOutline,
      mdiEyeOutline,
    },
    labelOwner: '',
    passedInstance: '',
    channelFromAlert: false,
    model: {
      name: '',
      variants: { variant: [] },
      color: '',
      channels: { channel: [] },
      hide: false,
    },
    allChannel: [],
    isLoadingLabel: false,
    instances: [],
  }),
  emits: ['fetch-tags'],
  computed: {
    displayedChannels() {
      return this.allChannel.map(channel => {
        const itemText = channel.label_server ? channel.label_server : channel.label

        return { ...channel, itemText }
      })
    },
    user() {
      return this.$store.getters['auth/getUser']
    },

    // instances() {
    //   const instances = this.$store.getters['auth/getListInstance'].value

    //   instances.push({
    //     label: 'All Channel',
    //     phone_number: 'all',
    //   })
    //   instances.forEach(item => {
    //     // //console.log('🚀 ~ file: DialogLabelPg.vue ~ line 162 ~ instances ~ item', item)
    //     item.label = `${
    //       (item.label && item.label.startsWith('-')) || item.label.includes('Default') || item.label.includes('62')
    //         ? item.label_server
    //         : item.label
    //     }`
    //   })

    //   return instances
    // },
    room() {
      return this.$store.getters['global/getCurrentRoom']
    },
  },
  watch: {
    channelFromAlert(varnew) {
      if (varnew) {
        // console.log(this.listLabel, 'WORKCHANNEL')
      }
    },
  },
  async mounted() {
    clientPocketBase.collection('crm_instances').subscribe('*', e => this.fetchChannel(e))
    const data = await this.$store.dispatch('auth/getAllInstances')
    const instances = data?.value || []

    instances.push({
      label: 'All Channel',
      phone_number: 'all',
    })
    instances.forEach(item => {
      // //console.log('🚀 ~ file: DialogLabelPg.vue ~ line 162 ~ instances ~ item', item)
      item.label = `${
        (item.label && item.label.startsWith('-')) || item.label.includes('Default') || item.label.includes('62')
          ? item.label_server
          : item.label
      }`
    })
    this.instances = instances
  },
  methods: {
    remove(item) {
      for (let i = 0; i < this.listLabel.length; i++) {
        const element = this.listLabel[i]
        const findIndex = element.variants.variant.indexOf(item)

        if (findIndex !== -1) {
          element.variants.variant.splice(findIndex, 1)
        }
      }
    },

    async fetchChannel({ action, record }) {
      // console.log(action, record, record.id, '<<this channel')
      // console.log(action, record.id, '<<this channel')

      const allChannel = await clientPocketBase.collection('crm_instances').getFullList(200, {
        filter: 'broadcast_group = false',
        expand: 'instance_label_name',
        $autoCancel: false,
      })

      const allTag = this.tags.length ? this.tags : await this.fetchAllTags()

      for (let i = 0; i < allChannel.length; i++) {
        const channels = allTag[i]?.channels?.channel || []

        for (let j = 0; j < channels.length; j++) {
          const channel = channels[j]

          if (record.phone_number === channel.phone_number) {
            const matchingChannel = allChannel.find(c => c.phone_number === channel.phone_number)
            channel.label = this.getChannelLabel(matchingChannel)

            if (matchingChannel) {
              if (record && action !== 'delete') {
                channels[j].id = record.id
                channels[j].label = this.getChannelLabel(matchingChannel)
              } else if (record && action === 'delete') {
                channels[j].label = ''
              }
            }
          }
        }

        const instance = allChannel[i]
        if (instance.instance_label_name) {
          instance.label = instance.expand.instance_label_name?.edited_name
          instance.label_server = instance.expand.instance_label_name?.edited_name
        }

        if (i < this.listLabel.length) {
          await this.updateTagChannels(allTag[i].id, channels)
        }
      }

      this.allChannel = allChannel
    },

    async fetchAllTags() {
      const getTags = await clientPocketBase.collection('crm_tags').getFullList(200, { $autoCancel: false })
      this.listLabel = getTags

      return getTags
    },

    getChannelLabel(channel) {
      if (channel) {
        return channel.expand.instance_label_name?.edited_name || channel.label || ''
      }

      return ''
    },

    async updateTagChannels(tagId, channels) {
      const updateTag = await clientPocketBase.collection('crm_tags').update(
        tagId,
        {
          channels: { channel: channels },
        },
        { $autoCancel: false },
      )
    },

    // async fetchChannel({ action, record }) {
    //   console.log(action, record, '<<this channel')
    //   // Fetch all CRM instances with broadcast_group = false
    //   const allChannel = await clientPocketBase.collection('crm_instances').getFullList(200, {
    //     filter: 'broadcast_group = false',
    //     expand: 'instance_label_name',
    //     $autoCancel: false,
    //   })

    //   // Fetch all CRM tags if they haven't been fetched already
    //   let allTag = this.tags || []
    //   if (!this.tags.length) {
    //     const getTags = await clientPocketBase.collection('crm_tags').getFullList(200, { $autoCancel: false })
    //     this.listLabel = getTags
    //     allTag = getTags
    //   } else {
    //     this.listLabel = allTag
    //   }
    //   console.log(allTag, allChannel, '<<this channel')

    //   // Update the channels in each tag
    //   for (let i = 0; i < allChannel.length; i++) {
    //     const channels = allTag[i]?.channels?.channel || []
    //     for (let j = 0; j < channels.length; j++) {
    //       const channel = channels[j]
    //       if (record.phone_number === channel.phone_number) {
    //         const checkId = allChannel.find(x => x.id === channel.id)
    //         const checkPhone = allChannel.find(x => x.phone_number === channel.phone_number)
    //         channel.label = checkPhone?.instance_label_name
    //           ? checkPhone.expand.instance_label_name.edited_name
    //           : checkPhone?.label || ''

    //         if (checkId && checkPhone) {
    //           channel.label = checkPhone?.instance_label_name
    //             ? checkPhone.expand.instance_label_name.edited_name
    //             : checkPhone?.label
    //         }

    //         // const matchingChannel = allChannel.find(c => c.phone_number === channel.phone_number)
    //         if (checkPhone) {
    //           // channels[j] = checkPhone
    //           if (record && action !== 'delete') {
    //             channels[j].id = record.id
    //             channels[j].label = checkPhone?.instance_label_name
    //               ? checkPhone.expand.instance_label_name.edited_name
    //               : checkPhone?.label
    //           } else if (checkPhone && record && action === 'delete') {
    //             channels[j].label = ''
    //           }
    //         }
    //       }
    //     }

    //     // Update the channels in the current tag
    //     const instance = allChannel[i]
    //     if (instance.instance_label_name) {
    //       instance.label = instance.expand.instance_label_name.edited_name
    //       instance.label_server = instance.expand.instance_label_name.edited_name
    //     }

    //     // Update the labels of the matching CRM instances
    //     if (i < this.listLabel.length) {
    //       const updateTag = await clientPocketBase.collection('crm_tags').update(
    //         allTag[i].id,
    //         {
    //           channels: { channel: channels },
    //         },
    //         { $autoCancel: false },
    //       )
    //     }
    //   }
    //   // Update the state of the component
    //   this.allChannel = allChannel
    // },

    async open(label, instance) {
      this.dialogLabel = true
      if (label) {
        this.listLabel = label
      }
      if (!this.allChannel.length) {
        const getChannel = await clientPocketBase.collection('crm_instances').getFullList(200, {
          filter: `broadcast_group = false`,
          $autoCancel: false,
        })
        this.allChannel = getChannel
      }

      // this.fetchChannel({})

      //   this.listLabel.forEach(x => {
      //     // //console.log('🚀 ~ file: DialogLabelPg.vue ~ line 190 ~ open ~ x', x)
      //     if (!x.channelId) {
      //       x.channelId = 'all'
      //     }
      //     this.menu.push(false)
      //   })

      // if (instance) {
      //   this.passedInstance = JSON.parse(JSON.stringify(instance))
      //   this.channelFromAlert = true
      // }

      // return new Promise((resolve, reject) => {
      //   this.resolve = resolve
      //   this.reject = reject
      // })
    },
    async agree() {
      this.isLoadingLabel = true
      const checkName = this.listLabel.find(el => el.name === '' || el.name === ' ')
      if (checkName) {
        this.errorTitleValidationSnackbar = true
      } else {
        for (let i = 0; i < this.listLabel.length; i++) {
          const element = this.listLabel[i]
          const nanoId = this.$nanoid()
          try {
            const updateTags = await clientPocketBase.collection('crm_tags').update(
              element.id,
              {
                color: element.color,
                name: element.name,
                variants: { variant: element.variants.variant || [] },
                subId: this.user.sub_id,
                channels: { channel: element.channels.channel },
                hide: element.hide,
              },
              { $autoCancel: false },
            )
          } catch (err) {
            const newTags = await clientPocketBase.collection('crm_tags').create(
              {
                id: this.$Md5Hash(`${this.user.sub_id}-${nanoId}`),
                color: element.color,
                name: element.name,
                variants: { variant: element.variants.variant || [] },
                subId: this.user.sub_id,
                channels: { channel: element.channels.channel },
                hide: element.hide,
              },
              { $autoCancel: false },
            )
          }
          this.$emit('fetch-tags')

          // this.$store.commit('inbox/setIsTagChanged', true)
        }
      }

      if (!this.errorTitleValidationSnackbar) {
        this.dialogLabel = false
      } else {
        this.dialogLabel = true
      }
      this.$emit('getLabelValue')
      this.isLoadingLabel = false

      // const labelColumn = this.tableColumns.find(x => x.value === 'label')
      // if (labelColumn) {
      //   labelColumn.listValue = this.listLabel

      //   // const self = this
      //   this.columnsSetting.value = this.tableColumns

      //   this.updateSettings(this.columnsSetting, async x => {
      //   })
      // }
      // const resSettings = await this.$store.dispatch('global/updateSettingsByKey', {
      //   sub_id: this.user.sub_id,
      //   token: this.user.token,
      //   key: 'label',
      //   value: this.listLabel,
      // })

      // Updated Label value
      // const curLabelValue = this.$store.getters['inbox/getLabelValue']
      // const currLabelValue = JSON.parse(JSON.stringify(curLabelValue))

      // const updatedLabelValue = currLabelValue.filter(lv => {
      //   return this.listLabel.filter(lb => lb.id === lv.label.id).length
      // })

      // let objFind
      // const newArr = []
      // currLabelValue.forEach(lb => {
      //   objFind = this.listLabel.find(el => el.id === lb.label.id)
      //   objFind ? (lb.label = objFind) : null
      //   newArr.push(lb)
      // })

      // Update global label value store
      // const resLabelValue = await this.$store.dispatch('global/updateSettingsByKey', {
      //   sub_id: this.user.sub_id,
      //   token: this.user.token,
      //   key: 'label_value',
      //   value: updatedLabelValue,
      // })
      // if (resSettings.status && resLabelValue.status) {
      // this.resolve({
      //   status: true,
      //   data: this.listLabel,
      // })
      // }
      this.channelFromAlert = false
    },
    cancel() {
      // this.resolve({
      //   status: true,
      //   data: [],
      // })
      this.dialogLabel = false
      this.labelOwner = ''
      this.channelFromAlert = false
    },
    async hideTags(label, id, data, index) {
      if (label.hide) {
        label.hide = false
        this.model.hide = label.hide
        this.listLabel[index].hide = label.hide
      } else {
        label.hide = true
        this.model.hide = label.hide
        this.listLabel[index].hide = label.hide
      }

      // //console.log(this.model.hide,   this.listLabel[index].hide, '<<Vira ')
    },
    isTagForm(listFormFilteredByContact, tagIdForm) {
      let isTagForm
      for (let i = 0; i < listFormFilteredByContact.length; i++) {
        const tagForm = listFormFilteredByContact[i]
        if (tagForm.tags !== null) {
          for (let j = 0; j < tagForm.tags.length; j++) {
            if (!isTagForm) {
              isTagForm = tagForm.tags[j].id === tagIdForm
            }
          }

          // console.log(isTagForm, '<<vira id tag')
          if (isTagForm === false) {
            return isTagForm
          }

          return true
        }
      }
    },
    async deleteLabel(index, id, data) {
      this.isLoadingLabel = true
      try {
        const allContactTag = await clientPocketBase.collection('crm_contact_tags').getFullList(200, {
          filter: `tag = '${id}' && sourceId = '' && messages = ''`,
          $autoCancel: false,
        })
        if (!allContactTag.length) {
          this.listLabel.splice(index, 1)
          await clientPocketBase.collection('crm_tags').delete(id, { $autoCancel: false })
          this.$emit('fetch-tags')
        } else {
          // dialog konfirmasi jika label sudah di assign
          this.$refs.dialogDeleteLabel.open(allContactTag, id, data, index)
        }
      } catch (err) {
        console.log(err, '< error delete')
      }
      this.isLoadingLabel = false
    },
    addNewLabel() {
      this.listLabel.push({
        id: uuid.v1(),
        color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
        value: '',
        name: '',
        variants: { variant: [] },
        channels: { channel: [] },
        hide: false,
      })

      // console.log(this.listLabel, '<<vira new column')
      this.labelOwner = ''
    },
  },
}
</script>
<style scoped>
.bottom-action {
  display: none;
}
.height-dialog {
  max-height: 400px;
  overflow-y: auto;
}
@media (max-width: 536px) {
  .hide-buton {
    display: none;
  }
  .bottom-action {
    display: inherit;
  }
  .block-condition {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
  }
}
</style>
