<template>
  <v-card>
    <!-- dialog select list -->
    <v-dialog v-model="dialogDeleteLabel" width="680px" height="85px" persistentHint>
      <v-card>
        <v-card-title>
          <span class="title-card">Delete Label</span>
          <v-spacer></v-spacer>
          <v-btn icon class="ml-auto" @click="closeDialogDelete">
            <v-icon color="black">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="mt-2 mb-2">
            <div class="text-title my-1">Data Label:</div>
            <div class="text-phone text-truncate">
              <v-avatar size="20" class="mr-2">
                <v-icon :color="label.color">
                  {{ icons.mdiLabelVariant }}
                </v-icon>
              </v-avatar>
              <span> {{ label.name }}</span>
            </div>
            <div class="text-name mt-8">
              {{ `This label is already in use, do you still want to delete this label ?` }}
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="text-end">
          <v-spacer></v-spacer>
          <v-btn class="mr-3" text color="primary" @click="closeDialogDelete">
            <span>{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="primary" class="" @click="deleteLabel">
            {{ $t('Delete') }}
          </v-btn>
          <v-progress-circular v-if="loading" indeterminate color="primary" class="mx-4"></v-progress-circular>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { default as clientPocketBase } from '@/helpers/pocketbase'
import { mdiClose, mdiLabelVariant } from '@mdi/js'
export default {
  name: 'dialogDeleteLabel',
  data: () => ({
    dialogDeleteLabel: false,
    resolve: null,
    reject: null,
    label: {},
    listLabel: [],
    icons: {
      mdiClose,
      mdiLabelVariant,
    },
    tagId: '',
    loading: false,
  }),
  emits: ['fetch-tags', 'fetch-all-tags'],
  methods: {
    async open(allContactTag, id, data, index) {
      this.dialogDeleteLabel = true
      this.listLabel = allContactTag
      this.tagId = id
      this.label = data[index]
    },
    isTagForm(listFormFilteredByContact, tagIdForm) {
      //untuk mengecek apakah label sudahh terdaftar pada form
      let isTagForm
      for (let i = 0; i < listFormFilteredByContact.length; i++) {
        const tagForm = listFormFilteredByContact[i]
        if (tagForm.tags !== null) {
          for (let j = 0; j < tagForm.tags.length; j++) {
            if (!isTagForm) {
              isTagForm = tagForm.tags[j].id === tagIdForm ? true : false
            }
          }
          if (isTagForm === false) {
            return isTagForm
          } else {
            return true
          }
        }
      }
    },
    async deleteLabel() {
      this.loading = true
      try {
        const contactForm = await clientPocketBase
          .collection('crm_contact_form')
          .getFullList(200, { filter: `tags ~ '${this.tagId}'`, $autoCancel: false })
        let tagIdForm = await this.isTagForm(contactForm, this.tagId)
        if (this.listLabel.length) {
          for (let i = 0; i < this.listLabel.length; i++) {
            const contactTag = this.listLabel[i]
            delete contactTag.rawData[this.tagId]
            const updateContactTags = await clientPocketBase.collection('crm_contact_tags').update(
              contactTag.id,
              {
                rawData: contactTag.rawData,
              },
              { $autoCancel: false },
            )
          }
        }
        //Untuk hapus  tag pada form
        if (tagIdForm) {
          for (let i = 0; i < contactForm.length; i++) {
            const tagForm = contactForm[i]
            delete tagForm.raw_data[this.tagId]
            const updateContactTags = await clientPocketBase.collection('crm_contact_form').update(
              tagForm.id,
              {
                raw_data: tagForm.raw_data,
                tags: tagForm.tags.filter(obj => obj.id !== this.tagId),
              },
              { $autoCancel: false },
            )
          }
        }
        await clientPocketBase.collection('crm_tags').delete(this.tagId, { $autoCancel: false })
        // this.$emit('fetch-tags')
        this.$emit('fetch-all-tags')
        this.dialogDeleteLabel = false
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    closeDialogDelete() {
      this.dialogDeleteLabel = false
    },
  },
}
</script>
<style scoped>
.title-card {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #323c47;
}

.text-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #030229;
}

.text-grey {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  height: 40px;
  width: 100%;
}

.text-name {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #dc3545;
}

.text-phone {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  color: #030229;
  padding-top: 4px;
}
</style>
